import {Navigate, Route, Routes} from "react-router-dom";
import React, {lazy} from "react";
import CreateAccount from "./admin/createAccount/CreateAccount";
import AlternativeLayout from "./common/layout/AlternativeLayout";

const LazyAdminHome = lazy(() => import('./admin/homepage/AdminHome'));
const LazyUpdatePassword = lazy(() => import('./oneTimePasswordUpdate/UpdatePassword'));

function AdminRoutes() {
    if(localStorage.getItem('temporary') === 'true') {
        return (
            <Routes>
                <Route path="/update-password" element={<AlternativeLayout component={<LazyUpdatePassword />}/>}/>
                <Route path="*" element={<Navigate to="/update-password" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<AlternativeLayout component={<LazyAdminHome />} />} />
            <Route path="/create-account" element={<AlternativeLayout component={<CreateAccount />} />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default React.memo(AdminRoutes);