import React, {useState} from "react";
import AdminHomeSidePanel from "../homepage/AdminHomeSidePanel";
import accountIcon from './images/create-account.svg';
import './stylesheets/create-account.css';
import useWindowWidth, {LG_SCREEN_WIDTH_PX} from "../../common/hooks/useWindowWidth";
import {useSubmitCreateAccount} from "./hooks/useSubmitCreateAccount";
import {useResetCreateAccountForm} from "./hooks/useResetCreateAccountForm";
import {useToastAutoDismiss} from "../../common/hooks/useToastAutoDismiss";

function CreateAccount() {

    document.title = 'Create Account | AD Solutions'
    useToastAutoDismiss();
    const windowWidth = useWindowWidth();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [role, setRole] = useState<string>('USER');
    const [eAutomateCustomerNumber, setEAutomateCustomerNumber] = useState<string | null>(null);
    const [connectwiseCustomerID, setConnectwiseCustomerID] = useState<number | null>(null);
    const [billingDayOfMonth, setBillingDayOfMonth] = useState<number>(1);
    const resetForm = useResetCreateAccountForm({
        resetEmailAddress: (setEmailAddress),
        resetRole: (setRole),
        resetEAutomateCustomerNumber: setEAutomateCustomerNumber,
        resetConnectwiseCustomerID: setConnectwiseCustomerID,
        resetBillingDayOfMonth: setBillingDayOfMonth,
        resetFirstName: setFirstName,
        resetLastName: setLastName
    });

    const handleSubmit = useSubmitCreateAccount({firstName: firstName, lastName: lastName, emailAddress: emailAddress, role: role, eAutomateCustomerNumber: eAutomateCustomerNumber, connectwiseCustomerID: connectwiseCustomerID, billingDayOfMonth: billingDayOfMonth, resetForm}, windowWidth);

    return (
        <div className="container-xl" style={windowWidth > LG_SCREEN_WIDTH_PX ? {paddingTop: '9rem', paddingBottom: '1.6rem'} : {paddingTop: '0'}}>
            <div className="d-flex justify-content-center p-0 px-3 pb-3 row">
                <AdminHomeSidePanel/>
                <div className="col-12 col-xl-9" style={{backgroundColor: 'white', borderRadius: '12px', border: '2px solid #E6E7E8'}}>
                    <div className="p-5">
                        <div className="d-flex justify-content-center p-3">
                            <img src={accountIcon} alt="create account" className="create-account-icon"/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <h3 style={{fontSize: '2.5rem'}}>Create Account</h3>
                        </div>
                        <form>
                            <div className="pb-5">
                                <h4 className="text-center" style={{fontWeight: 'lighter'}}>Fill in the fields below to
                                    create
                                    an account.</h4>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="firstName" className="form-label labels">First Name</label>
                                    <input type="text" className="form-control" id="firstName" value={firstName}
                                           onChange={(e) => setFirstName(e.target.value)}
                                           placeholder="John" required/>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label htmlFor="lastName" className="form-label labels">Last Name</label>
                                    <input type="text" className="form-control" id="lastName" value={lastName}
                                           onChange={(e) => setLastName(e.target.value)}
                                           placeholder="Doe" required/>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="emailAddress" className="form-label labels">Email Address</label>
                                <input type="email" className="form-control" id="emailAddress" value={emailAddress}
                                       onChange={(e) => setEmailAddress(e.target.value)}
                                       placeholder="user@myadsusa.com" required/>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="eAutomateCustomerNumber" className="form-label labels">eAutomate
                                        Customer Number</label>
                                    <input type="text" className="form-control" id="eAutomateCustomerNumber"
                                           value={eAutomateCustomerNumber === null ? '' : eAutomateCustomerNumber}
                                           onChange={(e) => setEAutomateCustomerNumber(e.target.value === '' || e.target.value === undefined  ? null :e.target.value)}
                                           placeholder="AD00" required/>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label htmlFor="connectwiseCustomerID" className="form-label labels">Connectwise
                                        Customer ID</label>
                                    <input type="number" className="form-control" id="connectwiseCustomerID"
                                           value={connectwiseCustomerID === null || connectwiseCustomerID === 0 ? '' : connectwiseCustomerID}
                                           onChange={(e) => setConnectwiseCustomerID(e.target.value === null || e.target.value === '' || connectwiseCustomerID === 0 ? null : Number(e.target.value))}
                                           placeholder="15" min={0} required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="role" className="form-label labels">Role</label>
                                    <select className="form-control" id="role" value={role}
                                            onChange={(e) => setRole(e.target.value)}>
                                        <option value="USER">User</option>
                                        <option value="ADMIN">Admin</option>
                                    </select>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label htmlFor="billingDayOfMonth" className="form-label labels">Billing Day of
                                        Month</label>
                                    <select className="form-control" value={billingDayOfMonth} id="billingDayOfMonth"
                                            onChange={(e) => setBillingDayOfMonth(Number(e.target.value))}>
                                        <option value="1">1st</option>
                                        <option value="15">15th</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn create-account-button mt-4" onClick={handleSubmit}>Create Account</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CreateAccount);