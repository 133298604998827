import {Link} from "react-router-dom";
import {useLogout} from "../authentication/hooks/useLogout";
import useGetRoute from "../common/hooks/useGetRoute";
import logoutIcon from "../common/images/logout.svg";
import React from "react";

export default function ToolsAndCustomerSidePanel() {

    const handleLogout = useLogout();
    const location = useGetRoute();

    return (
        <div className="col-xxl-3 col-xl-2 col-lg-0 align-items-center  mt-md-0">
            <div className="p-4 text-center text-lg-start mt-5 mt-lg-0">

                <h3 className="mb-4 ">MyADS Tools</h3>
                <Link to="/" className="text-decoration-none">
                    <h4 className="mb-3" style={location === "/" ? {color: '#0072ce'} : {}}>Invoice Summary</h4>
                </Link>
                <Link to="https://myadsusa.com/meter-read-submission/" className="text-decoration-none">
                    <h4
                        className="mb-3">Submit a Meter Reading</h4>
                </Link>
                <Link to="https://myadsusa.com/order-supply-form/" className="text-decoration-none">
                    <h4
                        className="mb-3">Order Supplies</h4>
                </Link>
                <Link to="https://myadsusa.com/service-request-form/" className="text-decoration-none">
                    <h4
                        className="mb-5">Request Service</h4>
                </Link>

                <h3 className="mb-3">Customer</h3>

                <Link to="/wallet">
                    <h4 className="mb-3" style={location === "/wallet" ? {color: '#0072ce'} : {}}>Wallet</h4>
                </Link>

                <Link to="/invoice-history" className="text-decoration-none">
                    <h4 className="mb-3" style={location === "/invoice-history" ? {color: '#0072ce'} : {}}>Invoice
                        History</h4>
                </Link>
                <Link to="/payment-history" className="text-decoration-none">
                    <h4 className="mb-3" style={location === "/payment-history" ? {color: '#0072ce'} : {}}>Payment
                        History</h4>
                </Link>

                <a href="#" onClick={handleLogout}
                   className="logout-link d-flex align-items-center text-center justify-content-center justify-content-lg-start mb-3"
                   style={{fontSize: '1.1rem'}}>
                    <img src={logoutIcon} alt="logout"
                         style={{width: '24px', height: '24px', marginRight: '8px'}}/>Logout
                </a>

            </div>
        </div>
    );

}