import toast from "react-hot-toast";
import {LG_SCREEN_WIDTH_PX} from "../hooks/useWindowWidth";
import '../stylesheets/toast.css'

export const errorToast = (message: string, windowWidth: number) => {
    toast.error(message, {id:"toast", position: 'top-right', duration: 3000, className:`${windowWidth > LG_SCREEN_WIDTH_PX ? 'toast-top-right-large-screen' : 'toast-top-right-small-screen'}`});
}

export const successToast = (message: string, windowWidth: number) => {
    toast.success(message, {id:"toast", position: 'top-right', duration: 2500, className:`${windowWidth > LG_SCREEN_WIDTH_PX ? 'toast-top-right-large-screen' : 'toast-top-right-small-screen'}`});
}

export const loadingToast = (message: string, windowWidth: number) => {
    toast.loading(message, {id:"toast", position: 'top-right', duration: 12000, className:`${windowWidth > LG_SCREEN_WIDTH_PX ? 'toast-top-right-large-screen' : 'toast-top-right-small-screen'}`});
}