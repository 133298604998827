import {errorToast} from "../../common/toasts/toastMessages";
import {useEffect, useState} from "react";

export function useIsAuthenticated(windowWidth: number) {

    const [isAuth, setIsAuth] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch('https://billing.myadsusa.com/api/authentication/check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });

                if (!response.ok) {
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('role');
                    localStorage.removeItem('temporary');
                    setIsAuth(false);
                } else {
                    const data = await response.json();
                    localStorage.setItem('role', data.role);
                    setIsAuth(data.isAuthenticated);
                }

            } catch (error) {
                errorToast('There was an issue checking authentication status. Please try again.', windowWidth);
            }
        }
        checkAuth();
    }, [localStorage.getItem('jwtToken')])

    return isAuth;
}
