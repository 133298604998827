import ConfigurePMSettings, {
    AutopayDetails,
    PaymentDetails,
    PaymentRecord,
    SavedBankAccount,
    SavedCardInt,

} from "../interface/paymentInterfaces";

import {Invoice} from "../../homepage/printSuppliesServiceEquipment/interfaces/sseInterfaces";
import {errorToast} from "../../common/toasts/toastMessages";

// Update Payment Intent
export default async function ConfigurePMSettingsWithPaymentIntent(params:ConfigurePMSettings, windowWidth: number) {
    try {
        const response = await fetch(`https://billing.myadsusa.com/api/stripe/configure-with-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                },
                body: JSON.stringify(
                    params)
            }
        );
        if (!response.ok) {
            errorToast('Failed to update payment method settings! Please try again.', windowWidth);
        }

        return await response.json();
    } catch (error: any) {
        errorToast(error.message, windowWidth);
    }
}


// Get Payment Intent
export async function GetPaymentIntent(params:PaymentDetails):Promise<{ clientSecret: string }> {
    const response = await fetch('https://billing.myadsusa.com/api/stripe/create-payment-intent', {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    const {clientSecret} = await response.json();
    return {clientSecret};}

// Get Invoices
export async function GetInvoices():Promise<Invoice[]> {
    try {
        const response = await fetch(`https://billing.myadsusa.com/api/invoices/list/due`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            }
        );
        if (!response.ok) {
            throw new Error('Failed to fetch invoices')
        }

        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Get Receipt Details
export async function GetReceiptDetails(paymentIntentID:string) {
    const response = await fetch('https://billing.myadsusa.com/api/stripe/receipt-details', {
        method: 'POST',
        body: JSON.stringify({"paymentIntentID":paymentIntentID}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    return await response.json();
}

// Replacement for the deprecated savedMethods
export async function getSavedBankAccounts():Promise<SavedBankAccount[]> {
    const response = await fetch('https://billing.myadsusa.com/api/stripe/saved-bank-accounts', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    return await response.json();
}

export async function getSavedCards():Promise<SavedCardInt[]> {
    const response = await fetch('https://billing.myadsusa.com/api/stripe/saved-cards', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    return await response.json();
}

export async function PaybySavedMethod(lastFour: string, paymentMethod: string, amount: number): Promise<string> {
    
        const response = await fetch('https://billing.myadsusa.com/api/stripe/pay-with-saved-method', {
            method: 'POST',
            body: JSON.stringify({
                lastFour,
                paymentMethod,
                amount
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
        });
        
       

        const responseData = await response.json();

        // Assuming the response contains only paymentIntentID
        const { paymentIntentID } = responseData;

        return paymentIntentID;
   
}


export async function getPaymentHistory():Promise<PaymentRecord[]> {
    const response = await fetch('https://billing.myadsusa.com/api/stripe/payment-history', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    return await response.json();
}

export async function getAutopayDetails():Promise<AutopayDetails> {
    const response = await fetch("https://billing.myadsusa.com/api/stripe/autopay-details", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
    });
    return await response.json();
}