import React, { useState } from "react";
import { Ripple } from "primereact/ripple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { motion } from "framer-motion";



interface IProps {
  open?: boolean;
  title: string;
  total ?: string;
  children : React.ReactNode
}

const Collapsible: React.FC<IProps> = ({children, title, total }) => {
  
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <>
      <div className="metercard">
        <div>
          <div className="d-flex align-items-center w-100">
            <div className="d-flex col-5" style={{paddingLeft:'1rem'}}><h6 className="caption-top fw-bold text-dark mb-0">{title}</h6></div>
            <div className="col-4"><h6 className="caption-top fw-bold text-dark mb-0 text-end">{total ? formatter.format(Number(total)): null}</h6></div>
            <div className="col-3 text-end"><button type="button" className="btn" onClick={handleFilterOpening}>

              {!isOpen ? (
                  <FontAwesomeIcon icon={faChevronDown as IconProp}/>
              ) : (
                  <FontAwesomeIcon icon={faChevronUp as IconProp}/>
              )}
              <Ripple/>
            </button>
            </div>
          </div>
        </div>
          {isOpen && <div className="p-0">
            <motion.div
                initial={{height: 0, opacity: 0}}
                animate={{height: 'auto', opacity: 1}}
                transition={{duration: 0.5}}
            >
              {children}
            </motion.div>
          </div>}
      </div>
      <div className="p-1"></div>
    </>
);
};

export default Collapsible;

