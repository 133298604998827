import CreateAccountProps from "../interfaces/createAccountInterfaces";
import {useCreateAccount} from "./useCreateAccount";
import {loadingToast} from "../../../common/toasts/toastMessages";

export function useSubmitCreateAccount(props: CreateAccountProps, windowWidth: number) {
    const createAccount = useCreateAccount(props, windowWidth);

    return async () => {
        loadingToast('Creating account...', windowWidth);
        await createAccount();
    };
}