import React from "react";
import ToolsAndCustomerSidePanel from "./ToolsAndCustomerSidePanel";
import PrintContract from "./printContract/PrintContract";
import "../resources/MainLayout.css"
import PrintSSEComponent from "./printSuppliesServiceEquipment/SuppliesServiceEquipment";
import TotalDueJumbotron from "./jumbotron/TotalDueJumbotron";
import "../resources/MainLayout.css";
import "./stylesheets/homepage.css"
import PayAndDownloadCurrentInvoiceButtonGroup from "../common/PayAndDownloadCurrentInvoiceButtonGroup";
import {useToastAutoDismiss} from "../common/hooks/useToastAutoDismiss";


const HomePage: React.FC = () => {
    document.title = "Pay My Bill | Home | AD Solutions";
    useToastAutoDismiss();

    return (
        <React.Fragment>
            <ToolsAndCustomerSidePanel />
            <div className="container-fluid" style={{minHeight: '70vh'}}>

                <div className="col-12 col-xl-10 col-xxl-9">
                    <div className="row mb-4">
                        <TotalDueJumbotron />
                    </div>
                    <PayAndDownloadCurrentInvoiceButtonGroup />

                    <div className="row mb-4">
                        <PrintContract />
                    </div>
                    <div className="row mb-4">
                        <PrintSSEComponent />
                    </div>
                    {/*<div className="row mb-4">
                        <VOIP />
                    </div>
                    <div className="row mb-4">
                        <ITService />
                     </div>*/}
                </div>

            </div>
        </React.Fragment>
    );
};

export default HomePage;
