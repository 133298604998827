import {Link} from "react-router-dom";
import React from "react";
import useLatestPDF from "../homepage/hooks/latestPDF";
import useWindowWidth from "./hooks/useWindowWidth";

function PayAndDownloadCurrentInvoiceButtonGroup() {
    const windowWidth = useWindowWidth();
    const latestPDF = useLatestPDF(windowWidth);

    const handleOpenPDF = async() => {
        window.open(await latestPDF.fetchPDF(), '_blank');
    }
    return (
        <div className="row mb-4">
            <div className="col-12 col-lg-6 pb-4 pb-lg-0 p-0">
                <div style={{paddingRight: '0.5rem'}}>
                    <button className="payBtn">
                        <Link to="/pay-my-bill">
                            <h5 className="" style={{
                                fontSize: '20px',
                                color: '#FFFFFF'
                            }}> Pay </h5>
                        </Link>
                    </button>
                </div>
            </div>
            <div className="col-12 col-lg-6 p-0">
                <div style={{paddingLeft: '0.5rem'}}>
                    <button className="downloadBtn" onClick={handleOpenPDF}>
                        <h5 className="" style={{fontSize: '20px', color: '#23A9E1'}}> Download Current
                            Invoice </h5>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default React.memo(PayAndDownloadCurrentInvoiceButtonGroup);