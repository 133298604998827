import React from "react";
import {Link} from "react-router-dom";
import {useLogout} from "../../authentication/hooks/useLogout";
import logoutIcon from "../.././common/images/logout.svg";
import useGetRoute from "../../common/hooks/useGetRoute";

function AdminHomeSidePanel() {
    const handleLogout = useLogout();
    const location = useGetRoute();
    return (
        <div className="col-xl-3 col-lg-0 d-flex justify-content-center justify-content-xl-end  mt-md-0">
            <div className="px-5 text-center text-xl-start mt-5 pt-5 pb-5 pb-xl-2 pt-lg-0 mt-lg-0">

                <h3 className="mb-3 ">Admin Tools</h3>
                <Link to="/" className="text-decoration-none">
                    <h4 className="mb-3" style={location === "/" ? {color: '#0072ce'}: {}}>Customer Accounts</h4>
                </Link>
                <Link to="/create-account" className="text-decoration-none">
                    <h4 className="mb-3" style={location === "/create-account" ? {color: '#0072ce'}: {}}>Create Account</h4>
                </Link>

                <div style={{margin: "15px"}}></div>
                <a href="#" onClick={handleLogout} className="logout-link d-flex align-items-center text-center justify-content-center justify-content-xl-start" style={{fontSize: '1.1rem'}}>
                    <img src={logoutIcon} alt="logout"
                         style={{width: '24px', height: '24px', marginRight: '8px'}} />Logout
                </a>

            </div>
        </div>
    );
}

export default React.memo(AdminHomeSidePanel);