import React, {lazy, Suspense} from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';
import MainLayout from "./common/layout/MainLayout";
import AlternativeLayout from "./common/layout/AlternativeLayout";
import Loading from "./common/Loading";
import useWindowWidth from "./common/hooks/useWindowWidth";
import {useIsAuthenticated} from "./authentication/hooks/useIsAuthenticated";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";

const LazyLogin = lazy(() => import('./authentication/Login'));
const LazyForgotPassword = lazy(() => import('./passwordReset/ForgotPassword'));

export default function App() {
    const windowWidth = useWindowWidth();
    const isAuth = useIsAuthenticated(windowWidth);
    const permittedNotAuthenticatedRoutes = ["/forgot-password"];

    const AuthRoutes = () => {
        const location = useLocation();

        if (permittedNotAuthenticatedRoutes.includes(location.pathname)) {
            return null;
        }
        return <Navigate replace to="/login" />;
    }

    if(isAuth === null) {
        return <Loading />;
    }

    return (
        <Router>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/forgot-password" element={<AlternativeLayout component={<LazyForgotPassword />} />} />
                    <Route path="/login" element={<MainLayout component={<LazyLogin/>} />} />
                </Routes>
                {isAuth ? (
                    <>
                        {localStorage.getItem('role') === 'ADMIN' ? (
                            <AdminRoutes />
                        ) : (
                            <UserRoutes />
                        )}
                    </>
                ) : (<AuthRoutes />)}
            </Suspense>
        </Router>
    );
}
