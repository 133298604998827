import {ResetCreateAccountForm} from "../interfaces/createAccountInterfaces";

export function useResetCreateAccountForm (resetForm: ResetCreateAccountForm) {
  return () => {
    resetForm.resetEmailAddress('')
    resetForm.resetRole('USER')
    resetForm.resetEAutomateCustomerNumber(null)
    resetForm.resetConnectwiseCustomerID(null)
    resetForm.resetBillingDayOfMonth(1)
    resetForm.resetFirstName('')
    resetForm.resetLastName('')
  }
}