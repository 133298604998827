import CreateAccountProps from "../interfaces/createAccountInterfaces";
import {errorToast, successToast} from "../../../common/toasts/toastMessages";

export function useCreateAccount(props: CreateAccountProps, windowWidth: number) {
    return async () => {
        try {
            if(props.role === 'USER' && props.eAutomateCustomerNumber === null && props.connectwiseCustomerID === null) {
                errorToast('Users need either a eAutomate Customer number or a connectwise customer id.', windowWidth);
                return;
            }
            const response = await fetch('https://billing.myadsusa.com/api/account/create-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')
                },
                body: JSON.stringify(props)
            });
            const data = await response.json()

            if (!response.ok) {
                errorToast(data.message, windowWidth);
            } else {
                props.resetForm();
                successToast(data.message, windowWidth)
            }
        } catch (error) {
            errorToast('An error occurred. Please try again.', windowWidth);}
    }

}