import {monthDict} from "../../common/miscFunctions";
import {errorToast} from "../../common/toasts/toastMessages";

export default function useLatestPDF(windowWidth: number) {

    const dateToGetPdfFor = new Date();
    dateToGetPdfFor.setMonth(dateToGetPdfFor.getMonth() - 1);



    const fetchPDF = async() => {
        try {
            const response = await fetch('https://billing.myadsusa.com/api/pdf/getPDF', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                },
                body: JSON.stringify({
                    year: dateToGetPdfFor.getFullYear(),
                    month: monthDict[dateToGetPdfFor.getMonth()]
                })
            });

            if (response.ok) {
                const blob = await response.blob();
                return URL.createObjectURL(blob);
            } else {
                const data = await response.json();
                errorToast(data.message, windowWidth);
                return '';
            }
        } catch (error) {
            errorToast('An error occurred. Please try again later.', windowWidth);
            return '';
        }
    }


    return {fetchPDF};
}